﻿$hoverColor: #eee;

.quantity-discounts-container {
    margin-top: 5px;

    thead th {
        padding-bottom: 5px;
        min-width: 80px;
        margin-right: 10px;

        &.units {
            text-align: right;
        }
    }

    table {
        margin-bottom: 10px;
    }

    & .quantity-discounts {
        & td {
            cursor: pointer;
            margin-right: 10px;
        }


        & .quantity-item {
            margin-right: 10px;
        }

        & .price-pr-unit {
            text-align: right;
        }

        & .quantity-discount-row:hover {
            background-color: $hoverColor;
        }

        & .quantity-discount-row.selected {
            background-color: $hoverColor;
        }
    }

    & .package-discounts {
        & .quantity-item {
            margin-right: 10px;
        }

        & .price-pr-unit {
            text-align: right;
        }

        & tr {
            cursor: pointer;
        }

        & .package-discount-row:hover {
            background-color: $hoverColor;
        }

        & .package-discount-row.selected {
            background-color: $hoverColor;
        }
    }
}
