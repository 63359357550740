﻿@import 'mixins';

.santander-calculator {

    .GeMoneyKalkulatorBorder {
        overflow: hidden;
        border-radius: 20px;
        border: 1px solid black;
        padding: 10px;
        margin-bottom: 15px;
        display: grid;
        grid-template-columns: 130fr 80fr 80fr 70fr;
        grid-template-rows: auto;
        grid-template-areas: "amount-label amount-select logo logo" "months-label months eff-label eff" "total-label total cost-label cost" "info info info info";
        grid-column-gap: 15px;
        grid-row-gap: 5px;
        align-items: center;

        @include toSizeMD {
            grid-template-columns: 1fr 1fr;
            grid-template-rows: auto;
            grid-template-areas: "logo logo" "amount-label amount-select" "months-label months" "eff-label eff" "total-label total" "cost-label cost" "info info";
        }
    }

    .santanderkalkulator-logo {
        grid-area: logo;
        width: 128px;
        max-width: 100%;
    }

    .santanderkalkulator-amount-select {
        max-width: 70px;
    }
        
    .santanderkalkulator-info {
        grid-area: info;
        font-size: 12px;
    }

    .santanderkalkulator-headerlabel {
        font-weight: bold;

        @include toSizeLG {
            margin-left: 5px;
        }
    }

    a {
        color: black;
    }

}

.svea-kalkulator {
    padding-bottom: 15px;
}

.svea-kalkulator-svea-logo {
    width: 70px;
    margin-right: 10px;
}

.svea-kalkulator-label {
    font-weight: bold;
    font-size: larger;
}

.kco-reload-cart-txt {
    text-align: center;
    padding-top: 20px;
    font-size: 16px;
}

.vipps-express-checkout-info-container {

    button.btn.btn-primary.buy-button-vipps-express-checkout {
        background-image: url(/css/images/vipps_ec.svg);
        background-color: transparent;
        background-repeat: no-repeat;
        margin-bottom: 10px;
        background-size: contain;
        height: 42px;
    }
}

.vipps-express-checkout-redirect {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 16px;
}

.nets-easy-process-checkout {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 16px;
}
