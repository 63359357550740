﻿@import 'variables';
@import 'mixins';

$borderColor: #d4e0ec;
$borderPopupColor: #39c;
$favBackgroundColor: transparent;
$favColor: #39c;

.WebPubElement {
    display: flex;
    width: 100%;
    overflow: hidden;
}

.ElementDivider {
    display: flex;
    width: 100%;
}

.Layout3Element {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    position: relative;
    box-sizing: border-box;
    border: 1px solid $borderColor;
    background: #f9f9f9;

    &:hover {
        border-color: #ccc;
        cursor: pointer;
    }
}

.ElementStandardArticle {
    width: 100%;
}

.ElementStandardArticle .textContent {
    @include toSizeSM {
        padding: 0 5px;
    }
}

/*Les mer side for standard artikkel*/
.ColumnCenter .ArticleContainer {
    @include bootstrapContainer();
    padding: 40px 0;
}

.Layout3Element .InfoOverlay {
    width: 100%;
    align-self: flex-end;
    padding: 5px 0;

    @include fromSizeSM() {
        padding: 10px 0;
    }
}

.Layout3Element .AddProductImage {
    width: 100%;
    position: relative;
    background: white;
}

.Layout3Element .AddProductImage a img {
    max-width: 100%;
    height: auto;
    position: relative;
}

.Layout3Element .AddHeaderContainer {
    margin-bottom: 8px;
}

.Layout3Element .AddHeaderContainer a {
    color: inherit;
}

.Layout3Element .AddHeader1,
.Layout3Element .AddHeader2 {
    white-space: nowrap;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

.Layout3Element .AddHeaderContainer a:hover {
    text-decoration: none;
}

.Layout3Element .AddHeader1 {
    font-size: 16px;
    font-weight: 300;

    @include fromSizeSM() {
        font-size: 18px;
    }
}

.Layout3Element .AddHeader2 {
    font-size: 14px;
    font-weight: 300;

    @include fromSizeSM() {
        min-height: 20px;
    }
}


.Layout3Element .review-score {
    margin-bottom: 5px;
}


/**** Prices ****/
.Layout3Element .AddPriceContainer {
    text-align: left;
    padding-left: 5px;

    @include fromSizeSM() {
        padding-left: 10px;
    }
}

.Layout3Element .PriceLabelContainer {
    display: inline-block;
}

.Layout3Element .old-price-container {
    display: inline-block;
}

.Layout3Element .AddPriceLabel,
.Layout3Element .AddOldPriceLabel {
    font-size: 16px;
    font-weight: bold;

    @include fromSizeSM() {
        font-size: 20px;
    }
}

.Layout3Element .AddOldPriceLabel {
    text-decoration: line-through;
    white-space: nowrap;
    color: #999;
}

.Layout3Element .AddPriceLabel {
    margin-right: 10px;
}

.Layout3Element .has-discount .AddPriceLabel {
    color: #991b1f;
}

.Layout3Element .veil-price-container {
    width: 100%;
    text-align: left;
    padding-left: 5px;
    line-height: 0.8em;

    @include fromSizeSM() {
        padding-left: 10px;
    }
}


/**** Stock ****/
.Layout3Element .AddStockContainer {
    position: absolute;
    right: 8px;
    bottom: 10px;
    display: none;

    @include fromSizeSM() {
        display: block;
    }
}

.Layout3Element .AddStockLabel {
    display: none;
}


/**** Buy button ****/
.Layout3Element .button-container {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
}

.Layout3Element .favorite-button,
.Layout3Element .fav-remove-button,
.Layout3Element .ad-buy-button {
    background: #eee;
    float: left;
    border-radius: 0;
    border: none;
    position: relative;
    left: -1px;
    height: 35px;
    line-height: 1.4em;
}

@include fromSizeSM() {
    .Layout3Element .favorite-button,
    .Layout3Element .fav-remove-button,
    .Layout3Element .ad-buy-button {
        display: none;
    }
}

.Layout3Element .ad-buy-button {
    width: 50%;
    padding: 1px 50px;
    font-size: 24px;
    font-family: $menuFont;
    font-weight: 300;
    /*border-right: 1px solid #ccc;*/
    @include toSizeSM() {
        display: none !important;
    }
}

.Layout3Element:hover .favorite-button,
.Layout3Element:hover .ad-buy-button {
    @include fromSizeLG {
        display: block;
    }
}

.pub-favorites .Layout3Element:hover .favorite-button {
    @include fromSizeLG {
        display: none;
    }
}

.check-favorite {
    display: none;
}

.pub-favorites .check-favorite {
    display: block;
    z-index: 9;
    margin: 10px;
}

.checkbox.check-favorite label {
    padding-left: 0;
}


.Layout3Element:hover .checkbox.mc-ui .cr {
    border: 2px solid $borderPopupColor;
}

.Layout3Element .heart-full {
    display: none;
}

.Layout3Element .favorite-button {
    border-radius: 0;
    font-size: 22px;
    padding: 2px 0 0 0;
    width: 40px;
    margin-left: 1px;
    z-index: 23;

    &:hover {
        padding-top: 0px;
    }

    @include toSizeSM() {
        background: $favBackgroundColor;
        color: $favColor;
    }
}

.pub-favorites .Layout3Element .favorite-button {
    display: none;
}

.Layout3Element .fav-remove-button {
    border-radius: 0;
    font-size: 22px;
    padding: 2px 0 0 0;
    width: 40px;

    &:hover {
        font-size: 24px;
    }

    &:hover .heart-full {
        display: block;
    }

    &:hover .heart-empty {
        display: none;
    }

    @include toSizeSM() {
        display: none !important;
    }
}

.pub-favorites .Layout3Element .ad-buy-button,
.pub-favorites .Layout3Element .fav-remove-button {
    @include toSizeSM() {
        display: block !important;
    }

    display: block;
    z-index: 1;
}

.pub-favorites .Layout3Element .favorite-button {
    @include toSizeSM() {
        display: none;
    }
}

.Layout3Element:hover .ad-buy-button[disabled] {
    background: #999;
    color: white;
}

/**** Ikoner ****/
.Layout3Element .YouSavePercentLabel {
    position: absolute;
    top: 5px;
    left: 5px;
    z-index: 1;
    font-weight: bold;
    background: $youSaveBackground;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: block;
    font-size: 16px;
    text-align: center;
    padding-top: 14px;

    @include fromSizeSM() {
        top: 10px;
        left: 10px;
        width: 70px;
        height: 70px;
        font-size: 24px;
        padding-top: 17px;
    }

    &::before {
        content: '-';
    }
}

.pub-favorites .Layout3Element .YouSavePercentLabel {
    left: 28px;

    @include fromSizeSM() {
        left: 28px;
    }
}

.AddAttributeIconContainer {
    position: absolute;
    top: -1px;
    right: -1px;
}

.Layout3Element .AttributeNewIcon {
    float: right;
    background: #7bd2eb;
    width: 40px;
    height: 25px;
    display: block;
    color: white;
    padding-top: 1px;
    text-align: center;
    font-size: 17px;
    font-family: $menuFont;

    @include fromSizeSM() {
        width: 70px;
        height: 30px;
        padding-top: 2px;
        font-size: 20px;
    }

    &:after {
        content: 'NY!';
    }
}

.Layout3Element .att-icon {
    float: right;
    clear: both;
    background-size: contain;
    background-repeat: no-repeat;
}

.Layout3Element .producer-logo {
    position: absolute;
    bottom: 0;
    right: 0;
    display: none;
    z-index: 22;

    @include fromSizeSM() {
        display: block;
    }
}


/**** Article with background ****/
.ArticleWithBackground {
    padding: 0;
    border: none;
    border-radius: 0;
    height: 200px;
    background: transparent;
    overflow: hidden;

    &:hover {
        cursor: initial;
    }
}

.ArticleWithBackground .inner-content,
.ArticleWithBackground .article-overlay {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
}

.ArticleWithBackground .inner-content {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    @include toSizeSM() {
        width: 100%;
    }
}

.PubAreaEditContainer .container-fluid,
.ArticleWithBackground .container-fluid {
    width: 100%;

    @include toSizeSM {
        padding: 0;
    }
}

.PubAreaEditContainer .container-fluid > div {
    @include toSizeSM {
        width: 100%;
        margin: 0;
    }
}

.ArticleWithBackground .mouse-over-effect {
    @include grow();
}

.ArticleWithBackground .heading-container {
    flex: 0 0 auto;
    max-width: 100%;

    @include fromSizeSM {
        white-space: nowrap;
    }

    * {
        font-size: 32px;
        letter-spacing: 2px;
        font-weight: bold;
        font-family: $menuFont;
        line-height: 40px;
        color: white;

        @include fromSizeSM {
            font-size: 40px;
        }
    }
}

.ArticleWithBackground .text-container {
    flex: 0 1 auto;
    text-align: left;
    color: white;
}

.ArticleWithBackground[data-link]:hover {
    cursor: pointer;
}

.article-parallax {
    background-attachment: scroll;
    /* Disable parallax for mobile and pad because it does not work well there*/
    @include fromSizeMD {
        background-attachment: fixed;
        opacity: 0;
    }
}

div.manufacturer-list {
    max-width: 1200px;
    clear: both;
}

.manufacturer-list .column {
    width: 50%;
    float: left;


    @include fromSizeMD() {
        width: 33%;
    }

    @include fromSizeLG() {
        width: 25%;
    }
}


.fullview .manufacturer-list .column {
    width: 50%;
    float: left;

    @include fromSizeSM() {
        width: 33%;
    }

    @include fromSizeMD() {
        width: 25%;
    }

    @include fromSizeLG() {
        width: 16.66666667%;
    }
}

.manufacturer-list .heading.column {
    width: 100%;
    clear: both;
}

.manufacturer-list a {
    color: $textColor;
    font-size: 16px;
    font-weight: 300;
}

.manufacturer-list a.letters {
    font-size: 28px;
}
/* Disable parallax for iOS because it does not work well there*/
.ios-detected .article-parallax {
    background-attachment: scroll;
    opacity: 1;
}

.can-animate {
    visibility: hidden;
}

.animated {
    visibility: visible;
}

.slideshow-container {
    .WebPubFieldRow {
        margin-bottom: 0;
    }

    .WebPubElement {
        margin-bottom: 0;
    }
}

.ArticleContainer {
    padding-bottom: 20px;
}
/* Make things look ok for IE9 */
.ElementStandardArticle {

    &.ArticleShowBorder {
        border: 1px solid $borderColor;
        padding: 10px;
    }

    &.ArticleRemovePadding {
        padding: 0;
    }
}
/* Make things look ok for IE9 */
.ie9 {

    .VerticalAdContainer {
        float: left;
        overflow: hidden;
        height: 400px;
    }

    .ArticleWithBackground .heading-container,
    .ArticleWithBackground .text-container {
        margin: auto;
    }
}
