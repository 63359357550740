﻿@import 'variables';
@import 'mixins';

/**** Favorites ****/
.favorite-header-article {
    width: 100%;
    padding: 15px;

    .checkbox label {
        padding-left: 0;
    }

    @include fromSizeSM() {
        padding: 15px;
    }
}

.WebPubElement .buy-favorites,
.WebPubElement .remove-favorites {
    color: #fff;
    background-color: #39c;
    border-color: #39c;
    font-weight: 300;
    font-family: Yanone Kaffeesatz,sans-serif;
    background: #39c;
    color: #fff;
    font-size: 24px;
    line-height: 1.4em;

    .buy-favorites-text {
        display: none;
    }

    @include fromSizeSM() {
        padding: 1px 22px;

        .buy-favorites-text {
            display: inline-block;
        }
    }
}

.fav-chk-content {
    padding: 10px 40px;

    @include fromSizeSM() {
        padding: 0 15px;
    }
}

.WebPubElement .remove-favorites {
    background-color: #fff;
    border-color: #ccc;
    color: #ccc;
   
     
    @include fromSizeSM() {
        width: 30%;
    }

    .remove-favorites-text {
      
    }
   
}

@include fromSizeLG {
    .favorite-content .Layout3Element:hover .fav-remove-button {
        display: block;
    }
}

button.buy-favorites {
    border-radius: 0 15px 15px 0;
    width: 50%;

    @include fromSizeSM() {
        width: auto;
        margin-right: 0;
    }
}

.favorite-buy-disable button.remove-favorites {
    border-radius: 15px;
}

button.remove-favorites {
    border-radius: 15px 0 0 15px;

    @include fromSizeSM() {
        width: 45% !important;
    }    
}

button.remove-favorites {
    @include fromSizeLG() {
        width: 30% !important;
    }
}

@include fromSizeSM() {
    .favorite-buttons {
        padding: 6px 12px;
        margin-bottom: 0;
        font-size: 20px;
    }
}

.favorite-content {

    .check-favorite {
        display: block;
    }

    .favorite-button {
        display: none !important;
    }
}

/* favorite small cart*/
.btn-fav-cart {
    padding: 0px 2px;
    font-size: 27px;
    margin-bottom: -7px;

    &.btn-link {
        &:hover,
        &:focus {
            text-decoration: none;
        }
    }

    @include fromSizeSM() {
        padding: 3px;
        margin-bottom: 0;
        font-size: 27px;
    }

    @include fromSizeMD() {
        padding: 6px;
        margin-bottom: 0;
        font-size: 27px;
    }
}

@include fromSizeSM() {
    i.glyphicon.glyphicon-heart-empty {
        top: 2px;
    }

    i.glyphicon.glyphicon-heart.heart-full {
        top: 2px;
    }

    .cart-small-fav {
        i.glyphicon.glyphicon-heart-empty {
            top: 0px;
        }

        i.glyphicon.glyphicon-heart.heart-full {
            top: 0px;
        }
    }
}

.fav-cart-text {
    top: 11px;
    margin-left: -24px;
    position: absolute;
    font-size: 12px;
    color: #fff;
    width: 24px;
    overflow: hidden;

    @include fromSizeSM() {
        //top: -6px;
        margin-left: -17px;
        font-size: 14px;
        display: inline-block;
        color: #434244;
        width: auto;
        // position: relative;
        overflow: visible;
        color: white;
    }
}

.mc-inline-editor .hidden.fav-inline-info {
    display: block !important;

    .show-inline-placeholder-info {
        display: none;
    }
}

.check-favorite-config {
    min-height: 14px;
    display: block;
}


.Layout3Element.has-variant-img {
    .ad-buy-button,
    .fav-remove-button {
        display: none;
    }

    .favorite-button {
        z-index: 99;
    }
}

.pub-favorites .has-variant-img {

    .variant-slider .rsThumbs {
       display: none;
    }

    .ad-buy-button,
    .fav-remove-button {
        display: block;
    }
}


.btn-favorite:active, 
.btn-favorite:focus, 
.btn-favorite:hover {
    box-shadow: none;
}