﻿@import 'variables';
@import 'mixins';

/*Nyhetsbrev*/

.Nyhetsmail {
    padding-top: 10px;

    @include toSizeSM {
        padding-left: 20px;
    }

    input {
        border: none;
    }

    .NyhetsmailHeader {
        display: none;
    }

    .InputContainer {
        width: auto;
        max-width: 300px;
        margin-top: 5px;
    }

    .RadioButtonSub,
    .RadioButtonUnsub {
        display: inline-block;
    }

    input[type="radio"] {
        margin-right: 3px;
        position: relative;
        top: 2px;
    }

    .RadioButtonSub {
        margin-right: 20px;
    }

    .TextBoxEmailReg {
        width: calc(100% - 60px);
        height: 32px;
        padding: 0 60px 0 8px;
        float: left;
        color: $textColor;
    }

    .SubscribeButton {
        font-weight: bold;
        height: 32px;
        width: 60px;
        float: left;
        text-align: center;
    }
    .ErrorInfo {
        clear: both;
    }
}
