﻿@import 'mixins';

/*Login*/
#loginout-content {
    display: inline;
}

.login-container {
    float: right;
    margin-top: 7px;
}

.LoginUserInfo {
    margin: 0;
    font-size: 14px;
    display: inline;
    margin-right: 10px;
}

.login-details {
    display: inline;
}

body.hide-ccenter {
    & .TopMenuItem.sid-1000007 {
        /* Hide customer center link when customer center is unavailable*/
        display: none;
    }

    & .login-details {

        & .LoginUserInfo {
            //disable the link on the name
            pointer-events: none;
            cursor: default;
            text-decoration: none;
            color: black;
        }
    }
}

.login-not-me {
    margin-left: 3px;
    margin-right: 3px;
    display: inline;
    font-size: 10px;
    cursor: pointer;
}
.LogInButton {
    text-transform: uppercase;
    padding-left: 20px;
    background: inline('images/lock.png');
    background-repeat: no-repeat;
    padding-top: 3px;
}

.refresh-txt {
    display:none;
}


@include toSizeSM {
    #login {

        .pub-area-a{
            margin:0;
        }

        .form-group.login-warning,
        .login-left {
            margin-bottom: 5px;
        }

        .login-header h2 {
            padding: 0;
            margin-top: 5px;
        }
    }
}

@include toSizeSM {
    #center-popup 
    {
        padding-right: 0 !important;

        .lightbox-close {
            margin-top: 7px;
            margin-right: 0;
            background-size: 100%;
            right: 7px;
            width: 30px;
            height: 30px;
        }
    }

    .form-horizontal .login-password a.control-label, .form-horizontal .password-cancel a.control-label {
        text-align: center;
    }
    .form-group.login-remember {
        text-align: center;
    }
    .login-customer-text p {
        font-size: 13px;
    }

    #lightboxCustomerRegistration .registration-req-info {
        margin-bottom: -15px;
        margin-top: 15px;
    }
    #login .login-password{
        margin-top:15px;
    }

    .form-group.login-g-btn {
        margin-bottom: 5px;
    }
}

@include fromSizeSM {
    #center-popup 
    {
        padding-top: 20px;
        padding-bottom: 20px;
    }    
}


.reset-password label {
    margin-top: 4px;
    margin-bottom: 10px;
}

.change-password .col-sm-4 {
    text-align: left;
}
.customer-registration-confirm .centered {
    text-align: left;
    padding-left: 0;
}
