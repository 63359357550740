﻿@import 'mixins';
@import 'variables';


/**** Department menu ****/
.mcm .nav,
.mcm .collapse,
.mcm .dropup,
.mcm .dropdown {
    position: static;
}

.navbar-default {
    background: transparent;
    margin-bottom: 0;
    border: none;
    box-shadow: none;
    /*z-index: 99; Bug: Small Cart*/
}

.navbar-collapse.collapse {
    padding: 0;
}

.navbar-default .navbar-nav > li > a {
    color: white;
    font-family: $menuFont;
    font-size: 24px;
    font-weight: 300;
    letter-spacing: 2px;
    text-transform: uppercase;

    &:hover,
    &:focus,
    &:active {
        text-shadow: none;
        color: white;
    }
}

.navbar-nav > li:first-child > a {
    padding-left: 0;
}

.navbar-nav > li:last-child > a {
    padding-right: 0;
}


/**** Dropdown part of the mega menu ****/
.mcm .dropdown-menu {
    top: inherit;
}

.dropdown .dropdown-menu {
    text-align: left;
    box-shadow: none;
    border-radius: 0;
    padding: 20px 18px 10px 20px;
    border: none;
    left: 0;
    right: 0;
    margin-left: -1px;
    margin-right: -1px;
    overflow: hidden;
}

.menu-items-container a {
    padding: 2px 0;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.menu-items-container a.show-all-sub {
    font-weight: bold;
}

.menu-items-container .title,
.dropdown-menu .menu-show-all {
    font-weight: bold;
    padding-left: 0;
    font-family: $menuFont;
    font-size: 24px;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.MegaMenuNotPublished,
.MegaMenuNotPublished .menu-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 0px; 
}

.dropdown-menu .col-xs-12 {
    padding: 0 0 15px 0;
}

.menu-items-container {
    padding-bottom: 15px;
    padding-right: 10px;
    padding-left: 0;
    width: 33.33%;

    &:nth-child(3n + 1) {
        padding-right: 0;
    }

    @include fromSizeMD() {
        width: 25%;

        &:nth-child(3n + 1) {
            padding-right: 10px;
        }

        &:nth-child(4n + 1) {
            padding-right: 0;
        }
    }

    @include fromSizeLG() {
        width: 20%;

        &:nth-child(4n + 1) {
            padding-right: 10px;
        }

        &:nth-child(5n + 1) {
            padding-right: 0;
        }
    }
}


.mc-menu-overlay .menu-container {
    z-index: 101;
    position: relative;
}

.m-header-dock.stickybar {
    position: relative;
    display: block;
}

.m-header-dock.stickybar.fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 102;
    margin-top: 0;
    background-color: #fff;

    .login-container,
    #content-special-offers,
    #special-offers-content,
    #special-offers-title,
    .top-menu {
        display: none;
    }

    a.header-logo {
        top: 4px;
    }
}

.menu-container.stickybar {
    position: relative;
    display: block;
}

.menu-container.stickybar.fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 102;
    margin-top: 0;

    .free-shipping-counter {
        display: none;
    }
}

.use-sticky-smallcart .menu-container.stickybar.fixed {
    top: 75px;
}


/* Hide publishing in mega menu, we do not use it in Demonstrare2016 design. */
.MegaMenuPublishedLeft,
.MegaMenuPublishedRight,
.MegaMenuPublishedBottom {
    display: none;
}
