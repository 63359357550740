﻿@import 'variables';
@import 'mixins';


/* Write review window */
.starNoPointer {
    width: 20px;
    height: auto;
}

.star {
    width: 20px;
    height: auto;
    margin-right: 2px;
    cursor: pointer;
}

.starList {
    height: 10px;
    width: 10px;
    border: 0px;
    margin-right: 1px;
    cursor: pointer;
}

.ReviewListDiv {
    position: relative;

    > div {
        position: absolute;
        bottom: 0;
        display: inline-block;
        max-height: 18px;
    }

    .review-star-icon,
    .icon-star {
        font-size: 18px;
        display: inline-block;
        max-height: 18px;
        line-height: 18px;
    }
}


.ReviewsDirectLink {
    padding-top: 10px;
    padding-bottom: 5px;
    font-weight: bold;
    display: block;
}

.ReviewImgRequired {
    float: left;
    margin-left: -12px;
}

.ReviewsCloseButton {
    float: right;
}

.ReviewEntryTable td {
    padding-bottom: 7px;
    padding-right: 5px;
}

.review-product-under-review {
    margin-top: 5px;
    font-style: italic;
}

.review-product-under-review a {
    font-weight: bold;
}

.PreviewButton {
    position: relative;
    left: 380px;
    bottom: -47px;
    width: 100px;
    height: 30px;
}

.ReviewerRecommends td {
    padding-left: 5px;
}

#InplcAwaitApproval {
    font-size: 14px;
}


/* Product information page */
.score-stars {
    float: right;

    .ReviewCount {
        color: $textColor;
        margin-left: 3px;
    }
}

.review-container {

    .ReviewText {
        white-space: pre-line;
    }

    .ElementDivider {
        margin-bottom: 40px !important;
    }

    .ElementDivider > table {
        border: none !important;
    }

    tr td:first-child {
        @include fromSizeSM() {
            width: 25% !important;
        }
    }

    h3 {
        font-size: 20px;
        margin-top: 0;

        @include toSizeSM {
            margin-top: 5px;
            margin-bottom: 0px;
        }
    }

    .ReviewerLink,
    .ReviewDate {
        display: inline-block;

        @include fromSizeSM {
            display: block;
        }

        @include toSizeSM() {
            margin-bottom: 5px;
        }
    }

    .ReviewerLink {
        @include toSizeSM {
            &:after {
                content: ' - ';
            }
        }
    }

    .ReviewDate {
        text-decoration: none;
        cursor: default;
    }

    .review-header span {
        font-size: 30px;
        text-transform: uppercase;
        width: 100%;
        text-align: center;
        font-family: $menuFont;
        display: inline-block;
        letter-spacing: 1px;
        margin-bottom: 20px;
    }

    .reviews-summary {
        width: 100%;
        clear: both;
        margin-bottom: 30px;
        overflow: hidden;
        text-align: center;
    }

    .average-score {
        display: inline-block;
        width: 100%;
        margin-bottom: 10px;
    }

    .average-score > span {
        margin-right: 30px;
    }

    .review-stars {
        display: inline-block;

        .starNoPointer {
            vertical-align: top;
        }
    }

    .ReviewCount {
        margin-left: 5px;
        font-size: 16px;
        vertical-align: bottom;

        &:hover {
            cursor: default;
            text-decoration: none;
        }
    }

    .write-review {
        text-transform: uppercase;
        border: 1px solid #ccc;
        padding: 5px 50px;
        border-radius: 15px;
        margin: auto;
        width: 300px;

        @include toSizeSM() {
            display: none;
        }
    }

    .WriteReviewLink {
        color: $textColor;
    }

    .ReviewerRecommends {
        width: 50%;
    }

    .ReviewerRecommends a {
        color: #337ab7;
    }

    .ReviewerRecommends td:first-child {
        width: auto !important;
    }
}

.layout-customerreviews {
    background: #f9f9f9;

    @include fromSizeSM() {
        background: transparent;
    }

    & > .row {
        padding: 10px;

        @include fromSizeSM() {
            padding: 0;
        }
    }
}


.review-table {
    tr,
    td {
        @include toSizeSM() {
            display: inline-block;
            float: left;
            width: 100%;
        }
    }
}

.review-container .review-reply td:nth-child(2n+2) {
    background: #dbdcde;
    padding: 10px;
}

.review-reply a.linkbutton {
    color: $textColor;
    cursor:pointer;
    font-weight:bold;
    font-style:italic;
}

.review-reply-heading{
    font-weight:normal;
    margin-bottom:10px;
}

