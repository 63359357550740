﻿@import 'variables';
@import 'mixins';

.customer-registration .conditions .checkbox > label {
    position: initial;
}

.condition-compact .glyphicon.glyphicon-chevron-up,
.condition-compact .glyphicon.glyphicon-chevron-down {
    font-size: 16px;
    padding: 0px 10px;
}

.condition-compact .glyphicon.glyphicon-chevron-up {
    display: none;
}

.condition-compact .glyphicon.glyphicon-chevron-down {
    display: inline-block;
}

.condition-compact.isExpanded .glyphicon.glyphicon-chevron-up {
    display: inline-block;
}

.condition-compact.isExpanded .glyphicon.glyphicon-chevron-down {
    display: none;
}

.condition-compact.isExpanded {
    border-bottom: 1px #e0e0e0 solid;
}

.condition-compact .condition-expander-container {
    cursor: pointer;
}

.condition-compact .condition-header,
.condition-compact .condition-ingress,
.condition-compact>div {
    width: 100%;
    clear: both;
}

.condition-compact .checkbox label {
    padding-left: 0px;
    float:left;
    max-width: calc(100% - 40px);
}

.condition-compact .checkbox {
    margin-left: 0px;
}

.condition-compact .icon-star {
    position: absolute;
    left: 17px;
    top: 0px;
    font-size: 9px;
    color: #808080;
    display: none;
}
.condition-onedocument .icon-star {
    display: none;
}

.condition-compact.isRequired .condition-header.checkbox label {
    font-weight: bold;
}

.condition-compact a {
    cursor: pointer;
}

.condition-onedocument.isRequired .checkbox label .condition-title {
    font-weight: bold;
}

.condition-user .condition-text{
    margin-top: 1em;
}

.condition-user .panel-footer label,
.condition-user .panel-footer .glyphicon-ok:before {
    font-size: 150%;
}

.conditionDocumentFull {
    height: 100%;
    max-height: 100%;
    width: 100%;
    max-width: 100%;
    padding: 15px;
}

.conditionDocumentFull .condition-header {
    display: none;
}

.conditionDocumentFull .glyphicon.glyphicon-chevron-up,
.conditionDocumentFull .glyphicon.glyphicon-chevron-down {
    display: none;
}

.conditionDocumentFull .condition-ingress {
    font-size: 16px;
}

.conditionDocumentFull .condition-text {
    margin: 1em 0px;
    max-height: 20vh;
    overflow-y: scroll;
    @include toSizeSM{ max-height: 75vh;}
}

.conditionsEdit .editBtn {
    display: none;
}

.condition-user .condition-text, 
.condition-onedocument .condition-text {
    max-height: 25vh;
    overflow-y: scroll;
}

.condition-title a:focus {
    text-decoration: none;
}

.condition-title .collapsed .glyphicon-chevron-up {
    display: none;
}

.condition-title .collapsed .glyphicon-chevron-down {
    display: inline-block;
}

.condition-title .glyphicon-chevron-up {
    display: inline-block;
}

.condition-title .glyphicon-chevron-down {
    display: none;
}


#mcweb-checkoutfooterview-footer {
    margin-top: 1.2em;
}

.postLoginConditions,
.deleteUserContainer {
    margin: 2em;
}

.postLoginConditions .TermsContainer {
    margin-bottom: 1em;
}

.postLoginConditions .condition-compact {
    border: 1px #e0e0e0 solid;
    border-radius: 5px;
    padding: 1em;
    margin: 1em 0em;
}
.postLoginConditions .storing-successful .glyphicon-ok {
    color: green;
    font-size: larger;
}

.postLoginAfterConditions {
    margin-bottom: 1em;
}

.condition-title a {
    display: inline-block;
    float: left;
}

.condition-title .acceptanceText {
    display: inline-block;
    float: right;
}
.acceptRadioContainer ,
.saveCancelButtonContainer {
    margin-bottom: 10px;
}


.customer-registration .conditions{
 margin-top: 1.5em;
}


.EdmCondtionResultContainer {
    text-align: center;
    .EdmCondtionResultMessage {
        font-size: 20px;
        & .icon-ok {
            font-size: 30px;
        }
    }
}

#checkout .conditions {
    margin-top: 2em;
}