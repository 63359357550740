﻿@import 'variables';
@import 'mixins';

.ProdListContainer {
    @include bootstrapContainer();
    padding-left: 0;
    padding-right: 0;
}

.ColumnCenterWithSidebarLeft .ProdListContainer {
    width: 100%;
}

/* Sort by */

.FieldSorterBottom .sort-by-container,
.FieldSorter .search-paging {
    @include toSizeSM {
        display: none;
    }
}

.FieldSorterBottom .FieldSorter .search-paging {
    @include toSizeSM {
        display: block;
    }
}

.search-no-result {
    @include toSizeSM {
        margin: 0 15px;

        h3 {
            font-size: 16px;
            text-align: center;
        }
    }
}

.FieldSorter.web-pub-field-sort {
    width: 100%;
    padding: 0 5px;
    text-align: center;

    @include fromSizeSM {
        display:block;
        text-align:left;
        padding: 0;
    }
}

.sort-by-container {
    border: 1px solid #ccc;
    margin: -5px 0 10px 0 !important;
    position: relative;

    .icon-down-open {
        display: block;
        z-index: -1;
        position: absolute;
        top: 7px;
        right: 20px;
    }

    @include fromSizeSM {
        position: initial;
        display: block;
        float: right;
        margin: 0 0 10px 0 !important;
        border: none;

        .icon-down-open {
            display: none;
            margin: -25px 0 2px 180px;
        }
    }
}

attribute-sort-box.produkt-filter-attributter {
    display: none;

    @include fromSizeSM {
        display: block;
    }
}


.sort-by-label {
    display: none;

    @include fromSizeSM {
        padding-top: 5px;
        padding-right: 10px;
        display: inline-block;
    }
}


.FieldSorter select,
.attribute-sort-box select {
    width: 100% !important;
    border: none;
    text-indent: 10%;
    padding: 7px 0;
    font-size: 16px;
    background-color:transparent;

    @include toSizeSM {
        appearance: none;
    }

    @include fromSizeSM {
        padding: 3px 0 3px 5px;
        width: 130px !important;        
        margin-top: 0;
        border: 1px solid #cccccc;
        border-radius: 3px;
        text-indent: initial;
    }
}





/* Paging */
.FieldPaging {
    float: left;
    margin-bottom: 10px;
    margin-top: 10px;
    padding-left: 10px;

    @include fromSizeSM() {
        float: none;
        padding: 0;
    }
}

.PrdListCellsPagingTop {
    display: none;

    @include fromSizeSM {
        display: block;
        float: left;
    }
}

.PrdListCellsPagingBottom {
    margin-bottom: 10px;
    /*margin-left: 10px;*/
}

.PrdListCellsPagingTop a,
.PrdListCellsPagingBottom a,
.FieldPaging a {
    background: #f5f5f5;
    color: #333;
    margin-right: 6px;
    margin-bottom: 10px;
    border-radius: 3px;
    font-size: 14px;
    text-align: center;
    display: inline-block;
    width: 42px;
    height: 38px;
    padding: 10px;

    @include fromSizeSM {
        margin-right: 4px;
        margin-bottom: 0;
        padding: 4px 0;
        width: 28px;
        height: 28px;
    }

    &:hover {
        text-decoration: none;
        background: #2a2a2e;
        color: white;
    }
}

.FieldPaging a {
    margin-right: 9px;

    @include fromSizeSM {
        margin-right: 4px;
    }
}

.PrdListCellsPagingTop a.SelectedPagingIndex,
.PrdListCellsPagingBottom a.SelectedPagingIndex,
.FieldPaging a.SelectedPagingIndex {
    background: #2a2a2e;
    color: white;
}


/*Produktliste*/

.ProdListContainer .TableList td {
    padding: 2px;
    border-top: 1px solid #DDDDDD;
    vertical-align: middle;

    @include fromSizeSM() {
        padding: 10px;        
    }
    
}

.ProdListContainer .TableList tr:last-child td {
    border-bottom: 1px solid #DDDDDD;
}


.ProdListContainer td.TableListVariantChild {
    padding: 5px;
    padding-right: 0;

    @include fromSizeSM() {
        padding: 10px;
        padding-right: 5px;

        &:first-child {
            padding-left: 5px;
            border-top: none;
        }

        &:last-child {
            padding-bottom: 0;
        }

        tr:last-child td {
            border-bottom: none;
        }
    }
}

.ProdListContainer th,
.ProdListContainer th a {
    font-weight: bold;
    color: $textColor;
}

.ProdListContainer .ProduktImg {
    margin: 0;

    img {
        max-width: 60px;
        height: auto;

        @include fromSizeSM() {
            max-width: none;
        }
    }
}

.TextBoxAntall {
    width: 42px;
    height: 30px;
    text-align: center;
    border: 1px solid #cccccc;
    border-radius: 4px;
}

.ProduktDesc a {
    text-decoration: none;
    color: $textColor;
}

.product-desc1 {
    font-weight: bold;
    font-size: 12px;

    @include fromSizeSM() {
        font-weight: normal;
        ;
        font-size: 16px;
    }
}

.product-desc2 {
    font-size: 12px;

    @include fromSizeSM() {
        font-size: 14px;
    }
}

.ProduktDesc,
.ProduktDesc span {
    line-height: 1em;

    @include fromSizeSM() {
        line-height: initial;
    }
}

.product-desc-prod-num-lbl,
.product-desc-stagtxt {
    color: $textMutedColor;
    font-size: 12px;
}

.product-desc-prod-num {
    font-weight: 300;
    color: $textMutedColor;
    font-size: 12px;
}

.ProduktListPris {
    font-weight: bold;
}

td.ProduktListPercent span {
    background: $youSaveBackground;
    border-radius: 2px;
    font-size: 18px;
    font-weight: bold;
    padding: 3px 5px;
}

.TableList .DynamicStockTooltipContainer {
    display: inline-block;
    font-size: 12px;
}

.TableList .StockIcon {
    margin-right: 5px;
    vertical-align: text-bottom;
}


.TableList .BuyButtonSmall,
.TableList .VariantShowMore,
.TableList .BuyButtonSmallDisabled {
    background: $textColor;
    border: none;
    border-radius: 4px;
    font-size: 21px;
    color: white;
    height: 34px;
    width: 66px;
    display: block;
    text-align: center;
        
    padding: 6px 0;
    
    

    &:hover {
        background: lighten($textColor, 10%);
        text-decoration: none;
        cursor: pointer;
    }
}

.TableList .BuyButtonSmall.list-vbuy-btn,
.TableList .VariantShowMore.list-vbuy-btn,
.TableList .BuyButtonSmallDisabled.list-vbuy-btn {
    display: inline-block;
    padding: 6px 12px;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
    overflow: hidden;
    width: auto;
    height: auto;
 @include fromSizeSM() {
        padding: 6px 19px;
    }

    @include fromSizeLG() {
        padding: 6px 12px;
    }
}

.TableList .VariantShowMore.list-vbuy-btn.has-favorite-btn {
    border-radius: 4px;

    @include fromSizeLG() {
        border-radius: 4px 0 0 4px;
    }

    &.variant-list-open {
        margin-right: 4px;
    }
}

.TableList .BuyButtonSmallDisabled {
    background: #999;

    &:hover {
        background: #999;
        cursor: not-allowed;
    }
}

.TableList .ProduktlistKjopInfo {
    display: none;
}

.TableList .ForPriceTxt,
.ProduktListVeilPrisCell {
    font-weight: normal;
}

.TableList .ForPriceTxt {
    text-decoration: line-through;
}

td.ProduktListPris {
    text-align: right;
    font-size: 12px;
    padding-left: 0;
    padding-right: 5px;
    white-space: nowrap;

    @include fromSizeSM() {
        font-size: 16px;
    }
}


/**** Variants ****/
.variant-dropdowns {
    margin-top: 5px;

    .VariantSelectorAttribOne {
        margin-right: 10px;
    }

    .VariantSelectorAttribOne,
    .VariantSelectorAttribTwo {
        float: left;
    }
}

.TableListVariantChild {
    background: #f9f9f9;

    .TableList {
        margin-top: 0;
    }

    tr:first-child td {
        border-top: none;
    }
}

td.TableListVariantChild {
    padding: 0;
}


.ProduktlistAttributtVerdi1Txt,
.product-desc-stagtxt,
.product-desc-news,
.TableList .SortListOnVariant2 {
    display: none;
}


/* Hide cells form mobile */
.ProdListContainer th,
.ProduktListVeilPris,
.ProduktListPercent,
.prod-list-quantity-cell, 
.prod-list-buy-cell,
.ProdListContainer .variant-dropdowns {
    @include toSizeSM() {
        display: none;
    }
}

.list-price {       
    white-space: nowrap;    
}
.list-price-content {    
    font-size: 12px;
    text-align: right;    
    font-weight:bold;
    @include fromSizeSM() {
        font-size: 16px;
    }
}

.TableList .qty-control.api-qty {
    display:none;
    @include fromSizeSM() {
        display: block;
    }
}

.TableList .QuantityTextBox {
    border: 1px solid #d0d0d2;
    width: 43px;
    padding: 5px 0;
    border-radius: 3px;
    height: auto;
}

/* Favorites */

.btn.btn-primary.has-favorite-btn {
    border-radius: 4px;
    
    @include fromSizeSM() {
        padding: 6px 18px;
    }

    @include fromSizeLG() {
        border-radius: 4px 0 0 4px;
        padding: 6px 12px;
    }
}

@include toSizeSM() {
    .btn.btn-primary.favorite-list-btn {
        display:none;
    }
}

.favorite-list-btn .heart-full {
    display: none;
}

.btn.btn-primary.list-buy-button.has-favorite-btn {
    @include toSizeSM() {
        display:none;
    }

    @include fromSizeLG() {
        border-radius: 4px 0 0 4px;
    }
}

.btn.favorite-list-btn {
    margin-top: 6px;
    padding: 2px 16px 0px 16px;
    border-radius: 4px;
    font-size: 21px;
    display: block;

    @include fromSizeSM() {
        padding: 2px 21px 0px 22px;
    }

    @include fromSizeLG() {
        display:inline-block;
        margin-top: 0;
        margin-left: -2px;
        border-radius: 0 4px 4px 0;
        padding: 2px 0 0 0;
        width: 35px;

        &.hide-buy {
            border-radius: 4px;
        }
        
    }

    &.list-fav-config{
        @include fromSizeSM() {
            padding: 2px 30px 0px 32px;
        }
        @include fromSizeLG() {
            padding: 2px 0 0 0;
        }
    }    
}