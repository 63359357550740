﻿@import 'mixins';

@media (min-width: 768px) {
    .modal-dialog.customer-review-window {
        width: 470px;
        margin: 30px auto;
    }
}


.btn-review {
    margin-top: 12px;
}

.review-desc {
    text-align: center;
}

.review-score {
    font-size: 30px;
}

.review-name {
    width: 200px;
}

.review-img {
    margin-bottom: 10px;
}

.review-btn {
    text-align: right;
}

.review-img {
    text-align: center;
}

.review-user {
    margin-top: 10px;
}

.rev-error {
    border: solid 1px red;
}

.rev-red {
    color: red;
}

.review-star-icon {
    font-size: 20px;
    color: #FFD700;

    i::before {
        margin: 0;
    }
}

.review-score {
    text-align: center;
}

.rating {
    direction: rtl;
}

.rating > div {
    display: inline-block;
    position: relative;
    width: 1.1em;
}

.rating > div:hover,
.rating > div.active:hover,
.rating > div:hover ~ div {
    color: #FFED85;
}

.rating > div.active {
    color: #FFD700;
}
.ReviewText {
    white-space: pre-line;
}