﻿@import 'variables';
@import 'mixins';

.ProductInfo > .container {
    padding-top: 0;
    padding-bottom: 20px;

    @include fromSizeSM() {
        padding-top: 20px;
        padding-bottom: 60px;
    }
}

.product-image-container {
    position: relative;
    width: 100%;
    z-index: 1; /* Bug fix: popup show small cart over */

    @include fromSizeSM() {
        width: calc(60% - 20px);
        float: left;
        margin-right: 20px;
    }
}
.product-image-container.rs-full-view {
    z-index: 110;
}

.product-info-container {
    padding: 10px 15px 20px 15px;
    margin-top: 10px;
    background: #f6f6f6;

    @include fromSizeSM() {
        padding: 0;
        margin: 0;
        width: 40%;
        float: left;
        background: transparent;
    }
}

/* Image slider */
.prod-image-slider.royalSlider {
    /*height: 400px;*/

    /*@include fromSizeMD() {
        height: 450px;
    }*/
    display: none;
}

.prod-image-slider.royalSlider.rsHor {
    /*height: 400px;*/
    /*@include fromSizeMD() {
        height: 450px;
    }*/
    display: block;
}

.prod-image-slider .rsThumb {
    border: 1px solid #ddd;
    width: 52px;
    height: 52px;

    @include fromSizeMD() {
        width: 82px;
        height: 82px;
    }
}
.prod-image-slider .rsThumb.rsNavSelected {
    border-color: #aaa;
}

.prod-image-slider .rsThumb.rsNavSelected img {
    opacity: 1;
}

.prod-image-slider .rsThumbsVer {
    left: 0;
    width: 52px;
    right: auto !important;

    @include fromSizeMD() {
        width: 82px;
    }
}

.prod-image-slider .rsOverflow {
    @include fromSizeSM() {
    }

    @include fromSizeMD() {
        margin-left: 82px !important; /* width of thumbnails container */
    }
}

.prod-image-slider.rsFullscreen {
    .rsThumbsVer {
        padding-left: 10px;
        width: 82px;

        @include fromSizeMD() {
            padding-left: 10px;
            width: 102px;
        }
    }

    .rsOverflow {

        @include fromSizeMD() {
            margin-left: 102px !important; /* width of thumbnails container */
        }
    }
}
.prod-image-slider .rsBullets {
    background: transparent;
    height: 20px;
    line-height: 13px;
    z-index: 9; /*fix header mobile*/
}


.prod-image-slider .rsBullet {
    box-shadow: none;
    border-radius: 3px;
    background: #d0d0d2;
    border: none;
    height: 3px;
    margin: 0 3px;
    width: 35px;
    padding: 0;

    &.rsNavSelected {
        background: #777;
    }
}

.prod-image-slider .rsBullet span {
    display: none;
}

.YouSavePercentProdImg {
    position: absolute;
    top: 0px;
    left: 10px;
    z-index: 1;
    font-weight: bold;
    background: #e6b74b;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: block;
    font-size: 16px;
    text-align: center;
    padding-top: 14px;

    @include fromSizeSM() {
        width: 60px;
        height: 60px;
        font-size: 22px;
        padding-top: 14px;
    }

    @include fromSizeMD() {
        top: 10px;
        left: 102px;
        width: 80px;
        height: 80px;
        font-size: 28px;
        padding-top: 18px;
    }

    &::before {
        content: '-';
    }
}


/**** Product information (right side) ****/
.ProductInfo .heading-container {
    width: 100%;
    border-bottom: 1px solid #c0c2c0;
    padding-bottom: 10px;
    position: relative;
    margin-bottom: 5px;

    @include fromSizeSM() {            
        padding-bottom: 10px;
    }
}

.ProductInfo .heading-container h1,
.ProductInfo .heading-container h2 {
    font-family: $menuFont;
    font-weight: normal;
    margin: 0;
    font-size: 30px;

    @include fromSizeSM() {
        font-size: 30px;
    }
}
.ProductInfo .heading-container h2 {
    font-size: 18px;
    font-weight: 300;

    @include fromSizeSM() {
        font-size: 22px;
    }
}
.ProducerLogo {
    float: right;
    clear: right;
    margin-top: 5px;
}

.product-number {
    font-weight: normal;
    margin-bottom: 10px;
    color: $textMutedColor;
}
.prd-num-label {
    font-weight: 300;
}

.ean-number-text {
    display: block;
}

.ean-number {
    font-weight: 300;
    display: block;
}

.ean-number-text .ean-number  {
    display: inline-block;
}

img.starNoPointer {
    @include toSizeSM { 
        width: 20px;
        height: auto;
    }
}


/**** Prices ****/
.product-prices {
    margin-bottom: 5px;    

    @include fromSizeMD() {
        float:none;
    }
}
.current-price-container {
    display: inline-block;
    margin-right: 60px;
    vertical-align: top;

    @include fromSizeMD() {
        margin-right: 80px;
    }
}
.old-price-container {
    display: inline-block;
    vertical-align: top;
}
.product-prices .PriceLabel,
.product-prices .OldPriceLabel {
    font-size: 28px;
    font-weight: bold;
    line-height: 26px;
    float: left;

    @include fromSizeMD() {
        font-size: 34px;
        font-weight: bold;
        line-height: 32px;
    }
}



#priceunit-offers,
#priceretail-offers  {
    display: none; /* TODO: Koden er ikke ferdig */
}



.product-prices .PriceLabel.has-discount {
    color: #991b1f;
}
.product-prices .OldPriceLabel {
    text-decoration: line-through;
    color: #999;
}
.regular-price-label,
.campaign-price-label {
    text-transform: uppercase;
    font-size: 11px;
    float: left;
    clear: both;
}
.regular-price-label {
    color: #999;
}
.retail-price-container {
    margin-bottom: 15px;
}


/**** Variant selector ****/
.variant-container {
    margin-bottom: 10px;
    margin-top: 15px;

    @include toSizeSM() {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }
}


/**** Quantity control ****/
.quantity-container {
    margin-bottom: 10px;
    margin-top: 15px;
    color: #888;
    clear: both;

    @include toSizeSM {
       text-align: center;
    }
    
    > span {
        display: inline-block;
        margin-bottom: 5px;
        width: 100%;
        font-weight: bold;
    }

    .PriceLabel {
        width: auto;
        margin-left: 10px;
    }    
}

$quantityColor1: #d0d0d2;
.qty-control {
    display: inline-block;
}
.less-qty,
.more-qty {
    float: left;
    display: block;
    text-align: center;
    font-size: 25px;
    width: 45px;
    height: 45px;
    border: 1px solid $quantityColor1;
    background: #eee;
    user-select: none;
    cursor: pointer;
    font-weight: normal;
    line-height: 40px;

    &:hover {
        background: white;
    }
}
.QuantityTextBox {
    float: left;
    text-align: center;
    border: 1px solid $quantityColor1;
    border-right: none;
    border-left: none;
    height: 45px;
    padding: 0;
    display: block;
    width: 45px;
    font-size: 16px;
}
input.QuantityTextBox:focus {
  outline-color: transparent;
  outline-style: none;
}


/**** Buy button ****/
.buy-info-container {
    margin-bottom: 10px;
}
.buy-product {
    min-width: 180px;
    width: 100%;
    padding: 5px 50px;
    font-size: 24px;
    font-family: $menuFont;
    font-weight: 300;
    line-height: 1.4em;
    border-radius: 0;
    border: none;

    @include fromSizeSM() {
        width: 50%;
        padding: 1px 50px;
    }
}

.buy-product.disable-button {
    background: #888;

    @include fromSizeLG {
        padding-right: 18px;
        padding-left: 20px;
    }

    &:hover {
        background: #888;
    }
}

    .buy-product[disabled] {
        background: #999;

        &:hover {
            background: #999;
            cursor: not-allowed;
        }
    }

    .section-menu .buy-product {
        display: none;
        width: 50px;
        float: right;
        margin-top: 5px;
        margin-bottom: 5px;
        margin-right: 15px;
        line-height: 30px;
        font-size: 20px;
    }

    .section-menu.fixed .buy-product {
        display: inline-block;
    }

    .body-hide-buy .btn.scoll-config-summary {
        display: none;
    }

    .buy-product.buy-button-mother, .buy-product.buy-button-mother:hover {
        background: grey;
    }
    /**** Favorites ****/
    .btn-favorite {
        font-size: 18px;
        line-height: 18px;
        min-width: 180px;
        width: 100%;
        overflow: hidden;
        margin-bottom: 10px;

        @include fromSizeSM() {
            width: 50%;
            padding: 4px 0px 4px 0px;
        }
    }

    .fav-text {
        padding-left: 5px;
        vertical-align: text-top;
    }
    /**** Stock *** */
    .product-stock {
        margin-bottom: 20px;
    }

    .main-warehouse,
    .remote-warehouse {
        font-weight: bold;
    }

    .ProductInfo .DynamicStockTooltipContainer {
        display: inline-block;
        font-weight: 300;
    }

    .ProductInfo .StockIcon {
        vertical-align: bottom;
        margin-right: 10px;
    }
    /**** Scrolling tab sections ****/
    .section-menu {
        display: none;
        position: relative;
        background: $textColor;

        @include fromSizeSM() {
            display: block;
        }
    }

    .section-menu.stickybar {
        z-index: 99;
    }

    .ProductInfo .section-menu .container {
        background: transparent;
    }

    .section-menu.fixed {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 99;
        margin-top: 0;
    }

    .section-menu-placeholder {
        display: none;

        @include toSizeSM() {
            display: none !important;
        }
    }

    .section-menu-item {
        color: white;
        text-transform: uppercase;
        padding: 4px 20px;
        font-family: $menuFont;
        font-size: 24px;
        float: left;
        display: inline-block;
        font-weight: 300;
        border-top: 2px solid transparent;

        &:hover {
            cursor: pointer;
            background: white;
            color: $textColor;
        }
    }

    .section-header .row {
        text-align: center;
        padding: 10px 0;

        @include fromSizeSM() {
            padding: 40px 0;
        }
    }


    i.i-plus {
        position: absolute;
        width: 12px;
        height: 12px;
        right: 5%;
        margin-top: 15px;

        @include fromSizeSM() {
            right: 5%;
        }

        @include fromSizeMD() {
            right: 15px;
        }

        &:before,
        &:after {
            content: "";
            position: absolute;
            background-color: #777;
            transition: transform 0.25s ease-out;
            border-radius: 3px;
        }
        /* Vertical line */
        &:before {
            top: 0;
            left: 50%;
            width: 2px;
            height: 100%;
            margin-left: -1px;
        }
        /* horizontal line */
        &:after {
            top: 50%;
            left: 0;
            width: 100%;
            height: 2px;
            margin-top: -1px;
        }
    }

    .section-header[aria-expanded="true"] i,
    .config-group-header[aria-expanded="true"] i {
        &:before {
            transform: rotate(90deg);
        }

        &:after {
            transform: rotate(180deg);
        }
    }

    .section-header[data-toggle="collapse"] .row,
    .config-group-header[data-toggle="collapse"] span {
        cursor: pointer;
    }

    @include toSizeSM() {
        .container-fluid.section-header {
            overflow: hidden;
        }
    }

    .section-header span {
        font-family: $menuFont;
        text-transform: uppercase;
        font-size: 28px;
        position: relative;
        font-weight: 300;
        letter-spacing: 2px;

        @include fromSizeSM() {
            font-size: 32px;
        }

        &::after {
            content: ' ';
            position: absolute;
            bottom: -5px;
            width: 80px;
            height: 2px;
            background: $textColor;
            display: inline-block;
            left: calc(50% - 40px);
        }
    }

    .section-content {
        padding-top: 10px;
        padding-bottom: 10px;

        @include fromSizeSM {
            padding-top: 0;
            padding-bottom: 0;
        }
    }
    /*.section-content span {
    font-family: $defaultFont;    
}*/
    .section-scroll-top {
        width: 33px;
        height: 33px;
        /*border: 1px solid white;*/
        border-radius: 20px;
        text-align: center;
        color: white;
        font-size: 20px;
        float: right;
        margin-top: 5px;
        margin-right: 10px;

        &:hover {
            cursor: pointer;
        }
    }
    /* Product description */
    .product-description {
        padding: 0 10px;
        overflow: hidden;

        @include fromSizeSM() {
            padding: 0;
        }

        table {
            @include toSizeSM() {
                width: 100% !important;
                max-width: 100% !important;
            }
        }

        img {
            @include toSizeSM() {
                max-width: 100% !important;
                height: auto !important;
            }
        }
    }
    /*Technical info*/
    .technical-info-header {
        display: none;
    }

    .technical-info br {
        display: none;
    }

    .technical-info table {
        margin-bottom: 0;

        td {
            word-break: break-all;
        }
    }

    .ProduktInfoWithTechnicalData {
        text-align: left;
    }
    /*Support section*/
    .ProductInfo .SupportLink {
        clear: both;
        float: left;
        text-decoration: none;
        color: #2a7ab5;

        &:hover {
            text-decoration: underline;
        }
    }

    .ProductInfo .support-container {
        padding-bottom: 20px;
        overflow: hidden;
    }
