﻿@import 'mixins';

/* =Pub Admin tools
-----------------------------------------------------------------------------*/
.PubAdmin {
    display: none;
    /* TODO: RS2016 */
    @include fromSizeSM() {
        display: block;
        color: red;
        position: fixed;
        left: 0px;
        top: 0px;
        width: 28px;
        height: 30px;
        background-color: Transparent;
        text-align: left;
        padding: 4px;
        border: solid 1px #999999;
    }
}

.PubAdminLayer {
    position: fixed;
    left: 0px;
    top: 0px;
    display: none;
    width: 200px;
    z-index: 1002;
}

.PubAdminLayerLinks {
    position: relative;
    height: 0px;
    z-index: 1;
    left: 0px;
    top: -15px;
    display: none;
    width: 200px;
}

.PubAdminBox {
    width: 80%;
    background-color: #ebeae5;
    border: solid 1px #333333;
    padding-top: 5px;
}

.PubAdminActivateButton:link {
    display: block;
    padding: 0px 5px 5px 5px;
    text-decoration: none;
}

.PubAdminActivateButton:hover {
    background-color: #d8d6ce;
    cursor: pointer;
}

.PubAdminActivateButton:visited {
    display: block;
    padding: 0px 5px 5px 5px;
    text-decoration: none;
}

.PubAdminLayerLinksToCopy {
    position: absolute;
    height: 0px;
    z-index: 1;
    left: 0px;
    top: 187px;
    display: none;
    width: 550px;
    padding: 10px;

    .PubAdminBox td {
        padding-bottom: 6px;
    }
}

.PubAdmin {
    z-index: 1001;
}
